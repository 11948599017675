<template>
  <div :style="btnStyle" draggable="true" @dragstart="drag($event)">
    <div :style="btncontent">
      <div v-if="showLang!='2'" class="textspan justify-center" :style="texeBold">
        {{ Name1 }}
      </div>
      <div v-if="showLang!='1'" class="textspan justify-center" :style="texeBold">
        {{ Name2 }}
      </div>
      <span :style="myiconstyle" @click.stop.prevent="removeButton($event)">{{ myicon }}</span>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'MenuBtn',
    props: {
      showLang: {
        type: String,
        required: true,
        default: 'B',
      },
      Name1: {
        type: String,
        required: true,
        default: '',
      },
      Name2: {
        type: String,
        required: false,
        default: '',
      },
      BtnColor: {
        type: String,
        required: false,
        default: '',
      },
      fontSize: {
        type: Number,
        required: false,
        default: 14,
      },
      fontWeight: {
        type: Number,
        required: false,
        default: 400,
      },
      fontColor: {
        type: String,
        required: false,
        default: '#000000',
      },
      mywidth: {
        type: Number,
        required: false,
        default: 100,
      },
      myheight: {
        type: Number,
        required: false,
        default: 100,
      },
      myicon: {
        type: String,
        required: false,
        default: 'X',
      },
    },
    data() {
      return {
        btnStyle: {
          backgroundColor:'#cad1d7',
          width:'200px',
          height:'100px',
          lineHeight:'1.5',
          border: '1px solid',
          borderRadius: '5px',
          display: 'inline-block',
          position: 'relative'
        },
        myiconstyle: {
          fontSize: '10px',
          position: 'absolute',
          top: '2px',
          right: '4px',
          color: 'red',
        },
        texeBold: {
          fontSize:'14px',
          fontWeight: 400,
          color: '#000000',
        },
        btncontent: {
          paddingTop: '10px',
          textAlign: 'center',
          width:'100%',
          height: '100%',
          overflow: 'hidden',
        }
      };
    },
    methods: {
      drag(evt) {
        this.$emit('dragstart', evt);
      },
      removeButton(ev) {
        ev.preventDefault();
        this.$emit('removeButton', ev);
      },
      handleClickOutside(evt) {
        if (!this.$el.contains(evt.target)) {
          ;
        }
      },
    },
    mounted() {
      var ctop = parseInt((this.myheight - 48) / 2);
      this.btncontent.paddingTop = ctop + 'px';

      this.btnStyle.width = this.mywidth + 'px';
      this.btnStyle.height = this.myheight + 'px';
      this.btnStyle.backgroundColor = this.BtnColor;
      this.texeBold.fontSize = this.fontSize + 'px';
      this.texeBold.fontWeight = this.fontWeight;
      this.texeBold.color = this.fontColor;
      document.addEventListener('click', this.handleClickOutside);
      if (this.myicon == 'C') {
        this.myiconstyle.border = '2px solid';
        this.myiconstyle.borderColor = 'pink green yellow blue';
      }
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside);
    }
  };
</script>
<style>
  .iconc {
    font-size: 10px;
    position: absolute;
    top: 2px;
    right: 4px;
    color: red;
    border: 1px solid;
    border-color: rgb(255, 251, 0) green blue pink;
  }
  .table-remove {
    font-size: 10px;
    position: absolute;
    top: 2px;
    right: 4px;
    color: red;
  }
</style>